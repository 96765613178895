import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConclusionFragment } from '../../entities/operationResults';
import ContentLink from '../atoms/ContentLink';
import Title from '../atoms/Title';
import { FixedGrid } from '../atoms/FixedGrid';
import CmsContent from '../atoms/CmsContent';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    data: ConclusionFragment;
}

export const conclusionFragment = graphql`
    fragment ConclusionFragment on Craft_ConclusionSuperTableBlockType {
        id
        conclusionTitle
        text {
            content
        }
        conclusionLink {
            text
            entry {
                uri
            }
        }
    }
`;

const Conclusion: React.FC<Props> = ({ data }) => {
    const { conclusionTitle, text, conclusionLink } = data;
    const uri = conclusionLink && conclusionLink.entry ? conclusionLink.entry.uri : 'werken-met';
    const site = useContext(SiteContext);
    return (
        <FixedConclusionGrid>
            <Wrapper>
                {conclusionTitle && <Title variant="huge">{conclusionTitle}</Title>}
                {text && text.content && <CmsContent html={text.content} />}
                <br />
                {conclusionLink && conclusionLink.text && uri && (
                    <span>
                        <ContentLink to={site ? `/${site}/${uri}/` : `/${uri}/`}>{conclusionLink.text}</ContentLink>
                    </span>
                )}
            </Wrapper>
        </FixedConclusionGrid>
    );
};

const FixedConclusionGrid = styled(FixedGrid)`
    margin-right: 10vw;
    z-index: 1;
`;

const Wrapper = styled.div`
    grid-column: 1 / 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-row: 1 / 29;
`;

export default Conclusion;
