import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ProfileFragment, VacancyRequirementFragment } from '../../entities/operationResults';
import BodyText from '../atoms/BodyText';
import Title from '../atoms/Title';
import { BulletList } from '../atoms/List';

export const vacancyRequirementFragment = graphql`
    fragment VacancyRequirementFragment on Craft_RequirementsSuperTableBlockType {
        heading
        items {
            text
        }
    }
`;

interface Props {
    data: VacancyRequirementFragment | ProfileFragment | null;
}

const VacancyRequirement: FC<Props> = ({ data }) => {
    return data ? (
        <>
            <Title>{data.heading}</Title>
            <BulletList>
                {data.items &&
                    data.items.map((item, i) =>
                        item ? (
                            <li key={i}>
                                <Text>{item.text}</Text>
                            </li>
                        ) : null
                    )}
            </BulletList>
        </>
    ) : null;
};

export default VacancyRequirement;

const Text = styled(BodyText)`
    margin: 0;
    max-width: 80rem;
`;
