import React, { FC } from 'react';
import { ProfileFragment, VacancyRequirementFragment } from '../../entities/operationResults';
import Grid from '../atoms/Grid';
import Cell from '../molecules/Cell';
import VacancyRequirement from '../molecules/VacancyRequirement';

interface Props {
    data: VacancyRequirementFragment[] | ProfileFragment[];
    margin?: number;
}

const VacancyRequirements: FC<Props> = ({ data: requirements, margin = 3 }) => {
    return requirements ? (
        <Grid marginRight={margin}>
            <Cell centerChildren horizontal={{ start: 1, end: { xl: 10, l: 12, m: 12, s: 12 } }}>
                {requirements[0] && <VacancyRequirement data={requirements[0]} />}
            </Cell>
        </Grid>
    ) : null;
};

export default VacancyRequirements;
