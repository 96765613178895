import styled from 'styled-components';
import { tinyCss } from './Title';

export const NumberedList = styled.ol`
    counter-increment: req;
    position: relative;
    list-style: none;
    margin: 0;

    &:before {
        position: absolute;
        top: 2em;
        left: -4rem;
        content: counter(req, decimal-leading-zero);
        margin-right: 1.8rem;
        font-weight: normal;
        position: relative;
        ${tinyCss}
    }
`;

export const BulletList = styled.ul`
    padding: 0;

    > * + * {
        margin: 0.5em 0 0 0;
    }
`;
