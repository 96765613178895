import { graphql } from 'gatsby';
import React, { FC, Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';
import {
    CTAFragment,
    ProfileFragment,
    VacancyPageFragment,
    VacancyPageQuery,
    VacancyRequirementFragment,
} from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import ArrowButton from './atoms/ArrowButton';
import BodyText from './atoms/BodyText';
import ContentLink from './atoms/ContentLink';
import { Section } from './atoms/Section';
import Title from './atoms/Title';
import ContentSection from './molecules/ContentSection';
import CTA, { getVariant, getWizardComponent } from './molecules/CTA';
import TeamMember from './molecules/TeamMember';
import Footer from './organisms/Footer';
import Hero from './organisms/Hero';
import Intro from './organisms/Intro';
import Supportive from './organisms/Supportive';
import VacancyRequirements from './organisms/VacancyRequirements';
import Meta from './atoms/Meta';
import CmsContent from './atoms/CmsContent';
import { removeTags } from '../utils/removeTags';
import Conclusion from './organisms/Conclusion';
import { BulletList } from './atoms/List';
import { SiteContext } from '../contexts/SiteContext';

interface Props {
    data: VacancyPageQuery;
}

export const query = graphql`
    query VacancyPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...VacancyPageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment VacancyPageFragment on Craft_Vacancy {
        id
        title
        postDate
        url
        hero {
            ...HeroFragment
        }
        intro {
            ...IntroFragment
        }
        supportive {
            ...SupportiveFragment
        }
        conclusion {
            ...ConclusionFragment
        }
        requirements {
            ...VacancyRequirementFragment
        }
        sellingPoints {
            ...SellingPointsFragment
        }
        contentSections {
            ...ContentSectionFragment
        }
        teamMemberQuestion {
            ... on Craft_TeamMemberQuestionSuperTableBlockType {
                heading
                callToAction {
                    ...CTAFragment
                }
                photo {
                    ...TeamMemberPhotoFragment
                }
            }
        }
        profile {
            ...ProfileFragment
        }
        callToAction {
            ...CTAFragment
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

const Vacancy: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    const entry = data.craft.entry as VacancyPageFragment;
    const hero = entry.hero && entry.hero[0];
    const intro = entry.intro && entry.intro[0];
    const supportive = entry.supportive && entry.supportive[0];
    const conclusion = entry.conclusion && entry.conclusion[0];
    const teamMemberQuestion = entry.teamMemberQuestion && entry.teamMemberQuestion[0];
    const sellingPoints = entry.sellingPoints && entry.sellingPoints[0];
    const profilePoints = entry.profile;
    const contentSections = entry.contentSections;
    const footer = entry.directContact && entry.directContact[0];
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const { requirements } = entry;
    const [showPopover, setShowPopover] = useState(false);
    const teamMemberQuestionCTA =
        teamMemberQuestion && teamMemberQuestion.callToAction && (teamMemberQuestion.callToAction[0] as CTAFragment);
    const Wizard = teamMemberQuestionCTA && getWizardComponent(teamMemberQuestionCTA.__typename);
    const meta = entry.seo && entry.seo[0];

    const skillsHtml =
        requirements && requirements[0] && requirements[0].items
            ? requirements[0].items.map((item) => `<li>${item ? item.text : ''}</li>`)
            : '';
    const benefitsHtml =
        sellingPoints && sellingPoints.items
            ? sellingPoints.items.map((item) => `<li>${item ? item.text : ''}</li>`)
            : '';
    const ldJsonData: object = {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        applicationContact: {
            '@type': 'ContactPoint',
            email: 'hr@oberon.nl',
            contactType: 'HR',
        },
        datePosted: new Date(entry.postDate * 1000).toISOString().substr(0, 10),
        description: intro?.subText?.content,
        employmentType: 'FULL_TIME',
        hiringOrganization: {
            '@type': 'Organization',
            legalName: 'Oberon Medialab B.V.',
            logo: 'https://d37pawv1vxxa2g.cloudfront.net/logos/logo-Oberon.png',
            name: 'Oberon',
            url: 'https://www.oberon.nl',
        },
        jobBenefits: `<ul>${benefitsHtml}</ul>`,
        jobImmediateStart: true,
        jobLocation: {
            '@type': 'Place',
            address: 'Amsterdam',
        },
        skills: `<ul>${skillsHtml}</ul>`,
        title: entry.title,
        url: entry.url,
    };

    const JsonLd = ({ ldData = {} }) => <script type="application/ld+json">{JSON.stringify(ldData)}</script>;

    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : hero && hero.description && removeTags(hero.description.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section>
                {hero && <Hero data={hero} ratio={759 / 1168} />}
                {intro && <Intro data={intro} variant="technique" />}
                {contentSections &&
                    contentSections.length > 0 &&
                    contentSections.map(
                        (contentSection, i) =>
                            contentSection && (
                                <ContentSection
                                    alignImage={i % 2 === 0 ? 'top' : 'bottom'}
                                    key={i}
                                    image={contentSection.image && contentSection.image[0]}
                                >
                                    <Title>{contentSection.heading}</Title>
                                    {contentSection.text && contentSection.text.content && (
                                        <CmsContent html={contentSection.text.content} />
                                    )}
                                    {contentSection.linkTo &&
                                        contentSection.linkTo.entry &&
                                        contentSection.linkTo.entry.uri && (
                                            <ContentLink
                                                to={
                                                    site
                                                        ? `/${site}/${contentSection.linkTo.entry.uri}/`
                                                        : `/${contentSection.linkTo.entry.uri}/`
                                                }
                                            >
                                                {contentSection.linkTo.text}
                                            </ContentLink>
                                        )}
                                </ContentSection>
                            )
                    )}
            </Section>
            <Section>
                {profilePoints && <VacancyRequirements data={profilePoints as ProfileFragment[]} margin={6} />}
                {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} align="right" withMargin />}
            </Section>
            <Section>
                {requirements && <VacancyRequirements data={requirements as VacancyRequirementFragment[]} />}
                {supportive && <Supportive data={supportive} />}
                {conclusion && <Conclusion data={conclusion} />}
                {teamMemberQuestion && teamMemberQuestion.photo && teamMemberQuestion.photo[0] && (
                    <TeamMember variant={'question'} photo={teamMemberQuestion.photo[0]}>
                        <Title>{teamMemberQuestion.heading}</Title>
                        {Wizard && teamMemberQuestionCTA && (
                            <>
                                {'title' in teamMemberQuestionCTA && (
                                    <CTAWrapper onClick={() => setShowPopover(true)}>
                                        <ArrowButton />
                                        <BodyText variant={'small'}>
                                            <strong>{teamMemberQuestionCTA.title}</strong>
                                        </BodyText>
                                    </CTAWrapper>
                                )}
                                <Wizard
                                    variant={getVariant(teamMemberQuestionCTA.__typename)}
                                    showPopover={showPopover}
                                    setShowPopover={setShowPopover}
                                    data={teamMemberQuestionCTA}
                                />
                            </>
                        )}
                    </TeamMember>
                )}
            </Section>
            {sellingPoints && (
                <SellingPointSection background={theme.colors.athensGray} fontColor={theme.colors.shark}>
                    <ContentSection>
                        <Title>{sellingPoints.heading}</Title>
                        <BulletList>
                            {sellingPoints.items &&
                                sellingPoints.items.map(
                                    (sp, i) =>
                                        sp && (
                                            <li key={i}>
                                                <BodyText>{sp.text}</BodyText>
                                            </li>
                                        )
                                )}
                        </BulletList>
                    </ContentSection>
                </SellingPointSection>
            )}
            <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} hasFlatTop />}</Section>

            <JsonLd ldData={ldJsonData} />
        </Fragment>
    );
};

export default withDefaultTransition(Vacancy);

const CTAWrapper = styled.a`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 2.5rem;
    text-decoration: none;
    color: initial;
    * + * {
        margin-left: 1.5rem;
    }
`;

const SellingPointSection = styled(Section)`
    @media (${theme.mediaQueries.horizontal}) {
        padding: 0 0 0 15vw;
    }
`;
